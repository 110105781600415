import { useReducer, useCallback, useEffect } from 'react'
import { useAuth, useFirebase } from 'hooks'
import { certificateAvailabilityDate } from 'utils/getCertificateAvailabilityDate'
import { parseFirebaseDate } from 'utils/firebase'

export const RESULTS = {
  COMPETENT: 'competent',
  NOT_COMPETENT: 'not_competent',
  NOT_PRESENTED: 'not presented',
  NOT_PERFORM: 'not_perform',
}

export const EXAM_STATE = {
  CORRECTING: 'correcting',
  CLAIMS: 'claims',
  CLOSED: 'closed',
  PENDING: 'pending',
}

function setDateTime(date, time) {
  // Split the time string into hours and minutes
  let [hours, minutes] = time.split(':').map(Number)

  // Create a new Date object to avoid mutating the original date
  let newDate = new Date(date)
  newDate.setHours(hours, minutes, 0, 0)

  return newDate
}

function addHoursToDate(date, hours) {
  // Create a new Date object to avoid mutating the original date
  let newDate = new Date(date)

  // Add the specified number of hours to the new Date object
  newDate.setHours(newDate.getHours() + hours)

  return newDate
}

export default function useExams() {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { loading: false, data: [], error: null }
  )
  const { user } = useAuth()
  const {
    getAllDocuments,
    examsColl,
    getNestedCollection,
    categoriesColl,
    examinersColl,
  } = useFirebase()

  const getExams = useCallback(async (user) => {
    const getState = ({ closed, closedDate, examDate, startTime }) => {
      const today = new Date()
      const formattedToday = today.toISOString().substring(0, 10)
      const exmDate = examDate.toISOString().substring(0, 10)

      const currentHour = today.getHours()
      const currentMinutes = today.getMinutes()
      const currentTime = `${currentHour}:${currentMinutes}`

      if (closedDate) {
        const newDate = certificateAvailabilityDate(closedDate)
        if (closed && formattedToday < newDate) return EXAM_STATE.CLAIMS
        if (closed && formattedToday > newDate) return EXAM_STATE.CLOSED
      }

      const timeToNumber = (time) => {
        const [hours, minutes] = time.split(':').map(Number)
        return hours * 60 + minutes
      }

      if (
        exmDate < formattedToday ||
        (exmDate === formattedToday &&
          timeToNumber(startTime) <= timeToNumber(currentTime))
      ) {
        return EXAM_STATE.CORRECTING
      }

      return EXAM_STATE.PENDING
    }

    const getResult = ({ th, p }) => {
      const passedValues = ['****', true]

      const checkValue = (value) => {
        if (passedValues.includes(value))
          return value === true ? RESULTS.COMPETENT : '****'
        if (value === false) return RESULTS.NOT_COMPETENT
        return RESULTS[value]
      }

      const resultTh = checkValue(th)
      const resultP = checkValue(p)

      const result =
        passedValues.includes(th) && passedValues.includes(p)
          ? RESULTS.COMPETENT
          : RESULTS.NOT_COMPETENT

      return {
        resultTh,
        resultP,
        result,
      }
    }

    try {
      const categoriesDocsPromise = getAllDocuments({
        coll: categoriesColl,
      })
      const examinersDocsPromise = getAllDocuments({
        coll: examinersColl,
      })
      const recordsPromise = getNestedCollection({
        collName: 'recordCandidates',
        key: 'docId',
        value: user.candidateDocId,
      })
      const doneExamsPromise = getAllDocuments({
        coll: examsColl(user.candidateDocId),
        orderKey: 'record',
      })

      const [categoriesDocs, examinersDocs, records, doneExams] =
        await Promise.all([
          categoriesDocsPromise,
          examinersDocsPromise,
          recordsPromise,
          doneExamsPromise,
        ])

      const categories = categoriesDocs.map(({ data }) => ({
        id: data.id,
        code: data.code,
      }))

      const examiners = examinersDocs.map(({ data }) => ({
        id: data.id,
        name: `${data.name} ${data.lastname}`,
      }))

      const exams = doneExams.map(({ data }) => data)

      const result = records
        .map((record) => {
          const category = categories.find(
            (category) => category.id === record.parent.category
          )
          const examiner = examiners.find(
            (examiner) => examiner.id === record.parent.examiner
          )

          let closed_date = record.parent.closed_date
          const thTime = record.parent?.crTime || record.parent?.ctpTime

          const recordData = {
            id: record.parent.docId.substring(-5, 5),
            date: parseFirebaseDate(record.parent.date),
            time: {
              th: thTime,
              p: record.parent?.p_time,
            },
            address: record.parent.address,
            examiner: examiner?.name,
            recordId: record.parent.code,
            record: category.code + record.parent.code,
            state: getState({
              closed: record.parent.closed,
              closedDate: closed_date ? parseFirebaseDate(closed_date) : null,
              examDate: parseFirebaseDate(record.parent.date),
              startTime: thTime,
            }),
            th: '',
            p: '',
            result: '',
            closed_date: closed_date ? closed_date.toDate() : null,
          }
          const examIndex = exams.findIndex(
            (exam) => exam.record === record.parent.code
          )
          if (examIndex === -1) return recordData

          const results = record.parent.closed
            ? getResult({
                th: exams[examIndex].result_th,
                p: exams[examIndex].result_p,
              })
            : null

          const startTime = setDateTime(recordData.date, thTime)
          // Finish time will be startTime + 3 hours
          const finishTime = addHoursToDate(startTime, 3)
          // It will be finished if the current time is greater than the finish time

          const isFinished = new Date().getTime() > finishTime.getTime()

          return {
            ...recordData,
            th: results?.resultTh,
            p: results?.resultP,
            result: results?.result,
            finished: isFinished,
          }
        })
        .sort((a, b) => b.date - a.date)

      setState({ loading: false, data: result, error: null })

      return result
    } catch (error) {
      setState({ loading: false, error })
      throw error
    }
  }, [])

  useEffect(() => {
    if (user?.candidateId && !state.data.length) {
      setState({ loading: true })
      getExams(user)
    }
  }, [user])

  return state
}
