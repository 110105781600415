export default function dateFormat(value, format) {
  const date = new Date(value)
  const year = date.getFullYear()
  const month = ('0' + (date.getMonth() + 1)).slice(-2)
  const monthName = months[date.getMonth()]
  const day = ('0' + date.getDate()).slice(-2)
  let formatDate

  switch (format) {
    case 'yyyy-MM-dd':
      formatDate = `${year}-${month}-${day}`
      break
    case 'long':
      formatDate = `${day} ${monthName} ${year}`
      break
    case 'dd MMM yyyy':
      formatDate = `${day} ${monthName.slice(0, 3)} ${year.toString()}`
      break
    default:
      formatDate = `${day}/${month}/${year}`
      break
  }

  return formatDate
}

const months = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre'
]