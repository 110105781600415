import { useEffect, useReducer } from 'react'
import {
  Stack,
  Group,
  Text,
  Box,
  Flex,
  ScrollArea,
  Skeleton,
  Center,
} from '@mantine/core'
import { useFirebase } from 'hooks'
import { dateFormat } from 'utils'
import { Calendar2 } from 'iconsax-react'

export default function Calendar({ height }) {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { loading: true, events: [], error: null }
  )

  const { getCalendarEvents } = useFirebase()

  useEffect(() => {
    getCalendarEvents()
      .then((resp) => {
        // console.log({ resp })
        setState({ events: resp, loading: false })
      })
      .catch((error) => {
        // console.log({ error })
        setState({ error, loading: false })
      })
  }, [])

  return (
    <ScrollArea
      bg="gray.2"
      style={{ height, borderRadius: 12 }}
      scrollbarSize={4}
    >
      <Stack spacing="xs" m={8}>
        {state.loading ? (
          new Array(3).fill('').map((it, idx) => <Event key={idx} />)
        ) : (
          <>
            {state.events.length > 0 ? (
              state.events.map((event) => <Event key={event.id} {...event} />)
            ) : (
              <Center mih={height - 8 * 2}>
                <Flex direction="column" align="center">
                  <Calendar2 size={48} variant="Bulk" />
                  <Text m={8} align="center">
                    Actualmente no hay ninguna convocatoria programada
                  </Text>
                </Flex>
              </Center>
            )}
          </>
        )}
      </Stack>
    </ScrollArea>
  )
}

const Event = ({ date, title, description }) => {
  return (
    <Group
      position="apart"
      p="xs"
      bg="white"
      sx={{
        borderRadius: 8,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flex: 1,
          gap: 8,
        }}
      >
        <Box
          component="span"
          bg="brand"
          display="block"
          mr={8}
          sx={{ width: 6, borderRadius: 4, height: 38 }}
        />
        <Box sx={{ flex: 1 }}>
          {title && description ? (
            <>
              <Text>{title.toUpperCase()}</Text>
              <Text fz="xs">{description}</Text>
            </>
          ) : (
            <>
              <Skeleton height={16} radius="xs" width={80} mb={4} />
              <Skeleton height={14} radius="xs" width={60} />
            </>
          )}
        </Box>
      </Box>
      {date ? (
        <Text color="dimmed" inline fz="sm">
          {dateFormat(date, 'long')}
        </Text>
      ) : (
        <Skeleton height={16} radius="xs" width={96} />
      )}
    </Group>
  )
}
