//GET method implementation:
export async function getData(url = '', auth) {
  return await fetch(url, {
    method: 'GET',
    headers: new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `Bearer ${auth}`,
    }),
  })
}

//PATCH method implementation:
export async function patchData(url = '', data = {}, auth) {
  return await fetch(url, {
    method: 'PATCH',
    headers: new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `Bearer ${auth}`,
    }),
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  })
}

//DELETE method implementation:
export async function deleteData(url = '', auth) {
  return await fetch(url, {
    method: 'DELETE',
    headers: new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `Bearer ${auth}`,
    }),
  })
}
